<template>
  <documents :deletable="false" />
</template>

<script>
import Documents from "../../../content/documents/Documents.vue";
export default {
  components: { Documents },
};
</script>

<style></style>
